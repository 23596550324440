#dietPlanDietChartTable {
  width: 100%;
  margin: 20px 0;
  th, td {
    padding: 10px;
  }
  tr {
    padding: 10px 0;
    border-bottom: 1px solid #999999;
  }
}

@import "../scss/variables";

.calendar-date-button {
  cursor: default;
  padding: 5px 0 0;

  &:focus {
    outline: none;
  }

  p {
    line-height: unset;
    margin: 0;
    text-align: center;
    color: $ecPrimaryColor;
  }

  .calendar-date-button-day {
    font-size: 0.55rem;
  }

  .calendar-date-button-date {
    //font-size: 0.8rem;
    //width: 32px;
    //height: 32px;
    //padding-top: 6px;
    //border-radius: 50%;
    //background-color: #EEEEEE;
  }
}

.calendar-date-button.calendar-date-button-isOpen {
  cursor: pointer;
  border-bottom: 2px solid #FFFFFF;

  &:hover {
    border-color: $ecPrimaryColor;
  }
}

.calendar-date-button.calendar-date-button-isDisabled {
  p {
    color: #DDDDDD;
  }
}

.calendar-date-button.calendar-date-button-isSelected {
  padding: 5px 7px 0;
  background-color: $ecPrimaryColor;
  p {
    color: #FFFFFF;
  }
}

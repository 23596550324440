@import "../scss/variables";

$tickWidth: 2;

$borderAnimationWidth: 5px;
$borderAnimationColor: #008ECA;

@keyframes active-blinking {
  0%   { border-bottom: $borderAnimationWidth solid $borderAnimationColor; }
  49% { border-bottom: $borderAnimationWidth solid $borderAnimationColor; }
  50% { border-bottom: none }
  99% { border-bottom: none }
  100% { border-bottom: $borderAnimationWidth solid $borderAnimationColor; }
}

.bookingTimelineDayLine {
  padding-left: 1px;
  padding-bottom: 5px;
  height: 30px;
  width: 100%;
  border-bottom: 2px solid $ecPrimaryColor;
  //border-top: 2px solid $ecPrimaryColor;
  //border: 2px solid $ecPrimaryColor;
  display: flex;
}

.bookingTimelineDaySlot:focus {
  outline: none;
}

//.blockedSlot {
//  background: repeating-linear-gradient(
//      40deg,
//      #DDDDDD,
//      #DDDDDD 2px,
//      #333333 2px,
//      #333333 4px
//  );
//}

.activeSlot {
  //animation: active-blinking 1s infinite;
  //border-bottom: $borderAnimationWidth solid #0DBEFD;
  background: repeating-linear-gradient(
      0deg,
      #0DBEFD,
      #0DBEFD 2px,
      #6CF1FF 2px,
      #6CF1FF 4px
  );
  //background-color: #0DBEFD !important;
  //border-bottom: 2px solid #008ECA;
  //border-top: 2px solid #008ECA;
}

.bookingTimelineDayTick {
  //position: absolute;
  width: $tickWidth * 1px;
  background-color: $ecPrimaryColor;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}

.bookingTimelineDayTickL {
  height: 12px;
}
.bookingTimelineDayTickM {
  height: 8px;
}
.bookingTimelineDayTickS {
  height: 5px;
}

.bookingTimelineDayLabelBox {
  display: flex;
  margin-top: 3px;
  //margin-left: -14px;
  .bookingTimelineDayLabel {
    width: 30px;
    p {
      width: 30px;
      margin: 0;
      font-size: 0.7rem;
      text-align: center;
    }

    .bookingTimelineDayLabelAmPm {
      font-size: 0.6rem;
    }
  }
}

#loginFormBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 100vh;
}

#loginFormBox {
  width: 300px;
}

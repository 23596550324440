.receptionistPatientAppointmentBox {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}
.receptionistPatientAppointmentBox:first-child {
  border-top: 1px solid #eeeeee;
}

.receptionistPatientAppointmentTiming {
  margin-right: 50px;
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
}

.receptionistPatientAppointmentName {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: bold;
}

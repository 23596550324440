@import "../scss/variables";

.taMenuItem {
  padding: 15px 20px;
  cursor: default;
  transition: color .3s cubic-bezier(.645,.045,.355,1),border-color .3s cubic-bezier(.645,.045,.355,1),background .3s cubic-bezier(.645,.045,.355,1),padding .15s cubic-bezier(.645,.045,.355,1);
}
.taMenuItemNotSelected {
  cursor: pointer;
}
.taMenuItem:focus {
  outline: none;
}
.taMenuItemNotSelected:hover {
  color: #c84c69;
}
.taMenuItemSelected {
  background-color: #c5d2ff;
  border-right: 3px solid #000e3b;
  color: #000e3b;
  font-weight: bold;
}

@import "variables";

@font-face {
  font-family: 'vonique_64bold';
  src: url('../fonts/vonique_64_bold-webfont.woff2') format('woff2'),
       url('../fonts/vonique_64_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

button:focus {
  outline: none !important;
}

#body {
  //max-width: 100vw;
  min-height: 100vh;
  background-color: $ecBackgroundColor;
  color: #230008 !important;
}

.logo {
  font-family: vonique_64bold;
  font-size: 1.5rem;
}

button.menu-icon-btn {
  width: 25px;
  height: 25px;
}

p {
  margin-bottom: 5px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

.ant-table-column-sorter-up.off, .ant-table-column-sorter-down.off {
  color: #BBBBBB;
}

.ant-table-column-sorter-up.on, .ant-table-column-sorter-down.on {
  color: $ecPrimaryColor;
}

@media print {
  body {
    font-size: 12pt !important;
    color: #000000 !important;
  }

  header {
    display: none !important;
  }

  .hideInPrint {
    display: none !important;
  }

  figure, th, td {
    page-break-inside: avoid !important;
  }
}

@page {
  size: A4;
  margin: 0.5in;
}

#patientsPageInfoTable {
  width: 100%;
  td {
    padding: 8px 15px;
  }
}

#patientPaymentsComponentInvoicesTable {
  margin-top: 15px;
  width: 100%;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;

  thead th {
    text-align: center;
    font-size: 1rem;
  }

  td, th {
    padding: 8px 15px;
    border-bottom: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
  }

  a {
    color: blue;
    text-decoration: underline;
    font-size: 0.95rem;
  }
}

.ecStatus {
  font-weight: bold;
  font-size: 1rem;
  font-style: italic;
  color: $ecPrimaryColor;
}

.reportsTable {
  width: 100%;
  border-top: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;

  thead th {
    background-color: #EEEEEE;
  }

  th, td {
    padding: 10px;
    border-bottom: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
  }
}

.reportsStatsTable {
  th, td {
    padding: 3px 10px;
    text-align: right;
  }
  td {
    font-size: 0.9rem;
  }
  th {
    font-size: 1rem;
  }
  td::after {
    content: ':';
  }
}

@import "../scss/variables";

#activeSubscriptionDietPlanSummaryTable {
  margin: 20px 0;
  td, th {
    padding: 3px 10px;
  }

  thead {
    th {
      text-align: center;
    }
  }

  tbody {
    td {
      text-align: center;
    }
  }
}

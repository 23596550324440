@import "../scss/variables";

@media print {
  #doctorAppointmentBox {
    padding: 0 !important;
  }

  .muiPaperPrint {
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  #doctorAppointmentContentCol {
    border: none !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .hideInPrint #dietPlanView {
    display: block !important;
  }
}

#dietPlanView {
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
}

#dietPlanViewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  * {
    margin: 0;
  }
}

#dietPlanViewBasicDetailsTable {
  width: 100%;
  margin: 20px 0;
  th, td {
    padding: 5px 10px;
  }
  th {
    text-align: right;
  }
  th::after {
    content: ' :';
  }
  td {
    text-align: left;
  }
}

#dietPlanViewDietChartTable {
  text-align: center;
  width: 100%;
  margin: 30px 0;
  border-top: 1px solid #999999;
  border-left: 1px solid #999999;
  th, td {
    padding: 15px;
    border-bottom: 1px solid #999999;
    border-right: 1px solid #999999;
  }
}

#dietPlanViewDietChartTableCol1 {
  width: 20%;
}
#dietPlanViewDietChartTableCol2 {
  width: 55%;
}
#dietPlanViewDietChartTableCol3 {
  width: 25%;
}

.dietPlanViewDietChartTableTime {
  font-weight: bold;
  font-size: 1rem;
}

@import "../scss/variables";

.calendar-month-btn {
  border: 1px solid $ecPrimaryColor;
  width: 80px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  padding-top: 3px;
  color: $ecPrimaryColor;
  cursor: default;

  &:focus {
    outline: none;
  }

  &.calendar-month-btn-not-selected {
    cursor: pointer;
  }
  &.calendar-month-btn-not-selected:hover {
    background-color: $ecBackgroundColor;
  }

  &.calendar-month-btn-selected {
    background-color: $ecPrimaryColor;
    color: #ffffff;
  }

  p {
    margin: 0;
  }
}
